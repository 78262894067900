import { useDispatch, useSelector } from "react-redux";
import { clearSuccessMessage } from "../../redux/actions/message";

const SuccessAlertLogic = () => {
  const dispatch = useDispatch();
  const { successMessage } = useSelector((state) => state.message);

  const closeAlert = () => {
    dispatch(clearSuccessMessage());
  };

  return {
    successMessage,
    closeAlert,
  };
};

export default SuccessAlertLogic;
