import api from "./api";

const login = (credentials) => api.post("auth/login", credentials);
const logout = () => api.post("auth/logout");
const changePassword = (passwords) =>
  api.put("auth/change-password", passwords);
const getUserData = () => api.get("auth");

const authService = {
  login,
  logout,
  changePassword,
  getUserData,
};

export default authService;
