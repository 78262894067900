import React from "react";
import Container from "react-bootstrap/Container";
import Header from "../../components/Header/Header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ChangePasswordLogic from "./ChangePasswordLogic";

export default function ChangePassword() {
  const logic = ChangePasswordLogic();
  return (
    <Container>
      <Header />
      <Row className="mt-5">
        <Col sm={4}>
          <h6>Old Password</h6>
          <Form.Control
            type="password"
            placeholder="please type your old password"
            id="password"
            onChange={logic.handleFieldChange}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col sm={4}>
          <h6>New Password</h6>
          <Form.Control
            type="password"
            placeholder="please type your new password"
            id="new_password"
            onChange={logic.handleFieldChange}
          />
          <Form.Control
            className="mt-2"
            type="password"
            placeholder="please retype your new password"
            id="new_password_retype"
            onChange={logic.handleFieldChange}
            isInvalid={
              logic.passwords.new_password_retype !== "" &&
              logic.passwords.new_password !==
                logic.passwords.new_password_retype
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {logic.errorMessage && (
            <p className="text-danger">{logic.errorMessage}</p>
          )}
        </Col>
      </Row>
      <Button variant="blue" onClick={logic.changePassword}>
        {logic.loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          "Change Password"
        )}
      </Button>
    </Container>
  );
}
