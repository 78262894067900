import { useState } from "react";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../redux/actions/message";
import authService from "../../services/auth.service";

const ChangePasswordLogic = () => {
  const dispatch = useDispatch();
  const [passwords, setPasswords] = useState({
    password: "",
    new_password: "",
    new_password_retype: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFieldChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.id]: e.target.value,
    });
  };

  const changePassword = () => {
    if (passwords.new_password === passwords.new_password_retype) {
      setLoading(true);
      authService
        .changePassword(passwords)
        .then((res) => {
          if (res.data.success) {
            dispatch(setSuccessMessage(res.data.message));
          } else {
            setErrorMessage(res.data.message);
          }
          setLoading(false);
        })
        .catch(() => {
          setErrorMessage("Failed to change the password");
          setLoading(false);
        });
    } else {
      setErrorMessage("The new password doesn't match");
    }
  };

  return {
    passwords,
    loading,
    errorMessage,
    handleFieldChange,
    changePassword,
  };
};

export default ChangePasswordLogic;
