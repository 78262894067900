import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  clearErrorMessage,
  setErrorMessage,
  setSuccessMessage,
} from "../../../redux/actions/message";
import publicService from "../../../services/public.service";

const PublicUpdateProjectLogic = () => {
  const { boardId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectDetail, setProjectDetail] = useState(null);
  const [emails, setEmails] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateProject = () => {
    setLoading(true);
    const email = emails.replace(/\s+/g, "");
    publicService
      .publicUpdateProject(boardId, { email })
      .then((res) => {
        if (res.data.success) {
          dispatch(clearErrorMessage());
          dispatch(setSuccessMessage(res.data.message));
          navigate(-1);
        } else {
          dispatch(setErrorMessage(res.data.message));
        }
        setLoading(false);
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to update the project"));
        setLoading(false);
      });
  };

  const back = () => {
    navigate("/project/" + boardId);
  };

  useEffect(() => {
    publicService
      .publicProjectDetail(boardId)
      .then((res) => {
        setProjectDetail(res.data.result.detail);
        res.data.result.detail.userEmail
          ? setEmails(res.data.result.detail.userEmail)
          : setEmails("");
        dispatch(clearErrorMessage());
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to load the project detail"));
      });
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [boardId, dispatch]);

  return {
    projectDetail,
    loading,
    emails,
    setEmails,
    updateProject,
    back,
  };
};

export default PublicUpdateProjectLogic;
