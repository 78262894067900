import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearErrorMessage,
  clearMessage,
  setErrorMessage,
  setSuccessMessage,
} from "../../redux/actions/message";
import projectService from "../../services/project.service";

const NewProjectLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [boardId, setBoardId] = useState(null);
  const [bulkHour, setBulkHour] = useState(null);
  const [bulkMinute, setBulkMinute] = useState(null);
  const [emails, setEmails] = useState(null);
  const [loading, setLoading] = useState(false);

  const addProject = () => {
    setLoading(true);
    const email = emails ? emails.replace(/\s+/g, "") : "";
    projectService
      .addProject({ boardId, bulkHour, bulkMinute, email })
      .then((res) => {
        if (res.data.success) {
          dispatch(clearErrorMessage());
          dispatch(setSuccessMessage(res.data.message));
        } else {
          dispatch(setErrorMessage(res.data.message));
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.data.message));
        } else if (error.request) {
          dispatch(setErrorMessage(error.request));
        } else {
          dispatch(setErrorMessage(error.message));
        }
        setLoading(false);
      });
  };

  const back = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    return () => {
      dispatch(clearMessage());
    };
  }, [dispatch]);

  return {
    setBoardId,
    setBulkHour,
    setBulkMinute,
    setEmails,
    addProject,
    back,
    loading,
  };
};

export default NewProjectLogic;
