import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Placeholder from "react-bootstrap/Placeholder";
import DataTable from "../../components/DataTable/DataTable";
import Header from "../../components/Header/Header";
import DashboardLogic from "./DashboardLogic";
import Navbar from "react-bootstrap/Navbar";

export default function Dashboard() {
  const logic = DashboardLogic();
  return (
    <Container>
      <Header />
      <Navbar variant="lightgrey" className="p-3 mt-3">
        {logic.totalHoursLogged ? (
          "Total hours logged: " + logic.totalHoursLogged
        ) : (
          <Placeholder xs={3} />
        )}
      </Navbar>
      <Row className="mt-3">
        <Col>
          <Button variant="blue" onClick={() => logic.navigate("/new-project")}>
            + Add new project
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={3}>
          <h6>Last Syncronized</h6>
          <Form.Select
            className="shadow border-0"
            onChange={(e) => logic.setSortLastSyncronized(e.target.value)}
            value={logic.sortLastSyncronized}
          >
            <option value={-1}>Newest (Default)</option>
            <option value={1}>Oldest</option>
          </Form.Select>
        </Col>
        <Col sm={3}>
          <h6>Bulk Hours</h6>
          <Form.Select
            className="shadow border-0"
            onChange={(e) => logic.setSortBulkHours(e.target.value)}
            value={logic.sortBulkHours}
          >
            <option value={-1}>Most (Default)</option>
            <option value={1}>Least</option>
          </Form.Select>
        </Col>
      </Row>
      <DataTable
        columns={logic.tableColumns}
        data={logic.projects}
        actionButtons={logic.tableActionButtons}
        hiddenColumns={logic.hiddenColumns}
      />
    </Container>
  );
}
