import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Header from "../../components/Header/Header";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Placeholder from "react-bootstrap/Placeholder";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import UpdateProjectDetailLogic from "./UpdateProjectDetailLogic";
import projectService from "../../services/project.service";
import { useParams } from "react-router-dom";

export default function UpdateProjectDetail() {
  const { boardId } = useParams();
  const [addHours, setAddHours] = useState(0);
  const [removeHours, setRemoveHours] = useState(0);
  const logic = UpdateProjectDetailLogic();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showAddBulkHoursModal, setShowAddBulkHoursModal] = useState(false);
  const [showRemoveBulkHoursModal, setShowRemoveBulkHoursModal] = useState(false);

  const isSubmitAddDisabled = Number(addHours) === 0;
  const isSubmitRemoveDisabled = Number(removeHours) === 0;
  useEffect(() => {
      if (showAlert) {
        logic.setBulkHour(null);
        logic.setBulkMinute(null);
        setAddHours(0);
        setRemoveHours(0);
        projectService.projectDetail(boardId)
        .then(res => {
            logic.setBulkHour(res.data.result.detail.bulkHour);
            logic.setBulkMinute(res.data.result.detail.bulkMinute);
            setShowAlert(false);
            setAlertMessage("");
            setAlertVariant("");
        })
        .catch(() => {
            console.log('Failed to load project detail');
        });

        //   setTimeout(() => {
        //       setShowAlert(false);
        //       setAlertMessage("");
        //       setAlertVariant("");
        //   }, 5000);
      }
  }, [showAlert, boardId, logic]);

  const addBulkHoursHandler = () => {
    setShowAddBulkHoursModal(false)
    let requestBody = {
      boardId,
      bulkHours: Number(addHours),
    };
    projectService
      .addBulkHours(requestBody)
      .then((response) => {
        // alert(response?.data?.message);
        // window.location.reload();
        // setAlertMessage(response?.data?.message);
        setAlertMessage(`Success add ${addHours} hours to the ${logic.projectDetail?.boardName}`);
        setAlertVariant("success");
        setShowAlert(true);
      })
      .catch((err) => {
        setAlertMessage("Failed to add bulk hours");
        setAlertVariant("danger");
        setShowAlert(true);
        console.log("Error add bulk hours", err);
      });
  };

  const removeBulkHoursHandler = () => {
    setShowRemoveBulkHoursModal(false)
    let requestBody = {
      boardId,
      bulkHours: Number(removeHours),
    };
    projectService
      .removeBulkHours(requestBody)
      .then((response) => {
        // alert(response?.data?.message);
        // window.location.reload();
        // setAlertMessage(response?.data?.message);
        setAlertMessage(`Success remove ${removeHours} hours to the ${logic.projectDetail?.boardName}`);
        setAlertVariant("success");
        setShowAlert(true);
      })
      .catch((err) => {
        setAlertMessage("Failed to remove bulk hours");
        setAlertVariant("danger");
        setShowAlert(true);
        console.log("Error remove bulk hours", err);
      });
  };

  const handleShowAddBulkHoursModal = () => setShowAddBulkHoursModal(true);
  const handleHideAddBulkHoursModal = () => setShowAddBulkHoursModal(false);

  const handleShowRemoveBulkHoursModal = () => setShowRemoveBulkHoursModal(true);
  const handleHideRemoveBulkHoursModal = () => setShowRemoveBulkHoursModal(false);

  return (
    <Container>
      <Header />
      {alertMessage && (
        <Alert variant={alertVariant} onClose={() => setAlertMessage("")} dismissible>
            {alertMessage}
        </Alert>
    )}
      <Row className="mt-5">
        <Col>
          {logic.projectDetail ? (
            <h4>{logic.projectDetail.boardName}</h4>
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={3} />
            </Placeholder>
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <h6>Bulk Hours Remaining</h6>
        <Col sm={5} lg={3}>
          {logic.bulkHour !== null && logic.bulkMinute !== null ? (
            <Stack direction="horizontal" gap={2}>
              <Form.Control
                value={logic.bulkHour}
                type="number"
                className="me-auto"
                onChange={(e) => logic.setBulkHour(e.target.value)}
              />
              <div>Hours</div>
              <Form.Control
                value={logic.bulkMinute}
                type="number"
                onChange={(e) => logic.setBulkMinute(e.target.value)}
              />
              <div>Minutes</div>
            </Stack>
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={7} />
            </Placeholder>
          )}
        </Col>
        <Col style={{ marginLeft: 50 }}>
          <Stack direction="horizontal" gap={2}>
            <Form.Control
              value={addHours}
              type="number"
              style={{ width: 200, borderRadius: 8, borderColor: "black" }}
              onChange={(e) => setAddHours(e.target.value)}
            />
            {/* <div style={{ cursor: "pointer" }} onClick={addBulkHoursHandler}>
              Add Bulk Hours
            </div> */}
            <Button
                variant="outline-success"
                style={{borderRadius: 8}}
                onClick={handleShowAddBulkHoursModal}
                disabled={isSubmitAddDisabled}
            >
                Add Bulk Hours
            </Button>
          </Stack>
        </Col>
        <Col>
          <Stack direction="horizontal" gap={2}>
            <Form.Control
              value={removeHours}
              style={{ width: 200, borderRadius: 8, borderColor: "black" }}
              type="number"
              onChange={(e) => setRemoveHours(e.target.value)}
            />
            {/* <div style={{ cursor: "pointer" }} onClick={removeBulkHoursHandler}>
              Remove Bulk Hours
            </div> */}
            <Button
                variant="outline-danger"
                style={{borderRadius: 8, minWidth: '180px'}}
                onClick={handleShowRemoveBulkHoursModal}
                disabled={isSubmitRemoveDisabled}
            >
                Remove Bulk Hours
            </Button>
          </Stack>
        </Col>
      </Row>
      <Row className="mt-3">
        <h6>User Email Client Email</h6>
        <Col sm={5}>
          <p>You can add up to 5 email(s), separated by coma (,)</p>
          {logic.emails !== null ? (
            <Form.Control
              value={logic.emails}
              as="textarea"
              type="text"
              placeholder="insert emails.."
              onChange={(e) => logic.setEmails(e.target.value)}
            />
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          )}
        </Col>
        <Col sm={5}>
          <p>Team Emails</p>
          {logic.teamEmails !== null ? (
            <Form.Control
              value={logic.teamEmails}
              as="textarea"
              type="text"
              placeholder="insert emails.."
              onChange={(e) => logic.setTeamEmails(e.target.value)}
            />
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {logic.projectDetail ? (
            <Stack direction="horizontal" gap={2}>
              <Button
                variant="blue"
                className="mt-3"
                onClick={logic.updateProject}
              >
                {logic.loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Update Project"
                )}
              </Button>
              <Button variant="darkblue" className="mt-3" onClick={logic.back}>
                Back
              </Button>
            </Stack>
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={2} />
            </Placeholder>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {logic.projectDetail ? (
            <Button
              variant="textred"
              className="mt-3"
              onClick={logic.handleShowDeleteModal}
            >
              Remove Project
            </Button>
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={2} />
            </Placeholder>
          )}
        </Col>
      </Row>


      {/* add bulk hours modal */}
      <Modal show={showAddBulkHoursModal} onHide={handleHideAddBulkHoursModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Bulk Hours</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure will add <b>{addHours} hours </b> to <b>{logic.projectDetail?.boardName}?</b></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideAddBulkHoursModal}>
            Cancel
          </Button>
          <Button variant="success" onClick={addBulkHoursHandler}>
            { logic.bulkHour === null && logic.bulkMinute === null ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Add Bulk Hours"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* remove bulk hours modal */}
      <Modal show={showRemoveBulkHoursModal} onHide={handleHideRemoveBulkHoursModal}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Bulk Hours</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure will remove <b style={{ color: "red" }}>{removeHours} hours </b> to <b>{logic.projectDetail?.boardName}?</b></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideRemoveBulkHoursModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={removeBulkHoursHandler}>
            { logic.bulkHour === null && logic.bulkMinute === null ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Remove Bulk Hours"
            )}
          </Button>
        </Modal.Footer>
      </Modal>


      {/* delete modal */}
      <Modal show={logic.showDeleteModal} onHide={logic.handleHideDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>Delete this project?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={logic.handleHideDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={logic.deleteProject}>
            {logic.deleteLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Delete Project"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
