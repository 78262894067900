import api from "./api";

const allProjects = (lastSynchronized, bulkHours) =>
  api.get(
    "projects?lastSynchronized=" + lastSynchronized + "&bulkHours=" + bulkHours
  );
const projectDetail = (boardId, startDate, endDate) => {
  if (startDate && endDate) {
    return api.get(
      "projects/" + boardId + "?startDate=" + startDate + "&endDate=" + endDate
    );
  } else {
    return api.get("projects/" + boardId);
  }
};
const addProject = (project) => api.post("projects", project);
const updateProject = (boardId, project) =>
  api.put("projects/" + boardId, project);
const deleteProject = (boardId) => api.delete("projects/" + boardId);
const synchronizeProject = (boardId) => api.patch("projects/" + boardId);
const addBulkHours = (requestBody) => api.put("bulkhours/add", requestBody);
const removeBulkHours = (requestBody) => api.put("bulkhours/decrease", requestBody)

const projectService = {
  allProjects,
  projectDetail,
  addProject,
  updateProject,
  deleteProject,
  synchronizeProject,
  addBulkHours,
  removeBulkHours
};

export default projectService;
