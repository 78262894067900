import {
  CLEAR_ERROR_MESSAGE,
  CLEAR_MESSAGE,
  CLEAR_SUCCESS_MESSAGE,
  SET_ERROR_MESSAGE,
  SET_SUCCESS_MESSAGE,
} from "./types";

export const setErrorMessage = (payload) => ({
  type: SET_ERROR_MESSAGE,
  payload,
});

export const setSuccessMessage = (payload) => ({
  type: SET_SUCCESS_MESSAGE,
  payload,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});

export const clearErrorMessage = () => ({
  type: CLEAR_ERROR_MESSAGE,
});

export const clearSuccessMessage = () => ({
  type: CLEAR_SUCCESS_MESSAGE,
});
