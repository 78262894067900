import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Logo from "../../assets/images/Logo.png";
import Dropdown from "react-bootstrap/Dropdown";
import Stack from "react-bootstrap/Stack";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import HeaderLogic from "./HeaderLogic";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import SuccessAlert from "../SuccessAlert/SuccessAlert";

export default function Header() {
  const logic = HeaderLogic();
  return (
    <>
      <Row className="mt-5">
        <Col>
          <Stack direction="horizontal" className="justify-content-between">
            <NavLink to="/dashboard">
              <Image src={Logo} fluid width="250px" />
            </NavLink>
            {logic.token && (
              <Stack direction="horizontal">
                <div>{logic.authData && logic.authData.result.username}</div>
                <Dropdown className="ms-2">
                  <Dropdown.Toggle size="sm" variant="darkblue" />

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logic.navigate("/dashboard")}>
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => logic.navigate("/change-password")}
                    >
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logic.logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Stack>
            )}
          </Stack>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <ErrorAlert />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <SuccessAlert />
        </Col>
      </Row>
      <Modal show={logic.logoutLoading} backdrop="static" keyboard={false}>
        <Modal.Body className="d-flex flex-row align-items-center">
          <Spinner animation="border" className="me-2" /> Logging out..
        </Modal.Body>
      </Modal>
    </>
  );
}
