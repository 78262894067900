import React from "react";
import EmailHeader50 from "./../../assets/images/email-header_50.png"
import EmailHeader15 from "./../../assets/images/email-header_15.png"
import EmailHeader5 from "./../../assets/images/email-header_5.png"
import EmailFooter50 from "./../../assets/images/email-footer_50.png"
import EmailFooter15 from "./../../assets/images/email-footer_15.png"
import EmailFooter5 from "./../../assets/images/email-footer_5.png"
import ReportButton from "./../../assets/images/report-button.png"

const EmailComponent = () => {
    const userDetail = {
        name: 'Anthony',
        remainingHours: '48h 51m',
        bulkHoursLogged: '51h 00m'
    }
    return (
        <div>
            <div className="center-div">
                <div className="width-600">
                    <img src={EmailHeader50}/>
                    <div className="margin-50">
                        <div>
                            <p>Happy Monday, {userDetail?.name}</p>
                            {/*Saturday, July 30 -> send email date*/}
                            {/*sunday, july 23 -> send email date dikurang 7 hari*/}
                            <p>Your weekly report of Bulk Hours logged from Sunday, July 23, to Saturday, July 30, 2022,
                                is here! Last</p>
                            <p style={{fontWeight: "bolder", marginTop: 10}}>Last Weeks Highlights:</p>
                        </div>
                        <div className="button-row">
                            <button className="button-time bg-linear linear-purple">
                                <div>
                                    <h3 style={{fontWeight: 'bold'}}>{userDetail?.remainingHours}</h3>
                                    <p style={{fontSize: 14}}>Bulk Hours Remaining</p>
                                </div>
                            </button>
                            <button className="button-time">
                                <div>
                                    <h3 style={{fontWeight: 'bold'}}>{userDetail?.bulkHoursLogged}</h3>
                                    <p style={{fontSize: 14}}>Bulk Hours Logged</p>
                                </div>
                            </button>
                        </div>
                        <p>If you’d like to see a line item breakdown on each task we worked on,
                            please view the weekly Bulk Hours Report below:</p>
                        <img src={ReportButton} className={"image-button"}/>
                    </div>
                    <img src={EmailFooter50}/>
                </div>
                {/*<a href={"http://www.google.com/"} target="_blank">*/}
                {/*    <button className={"bg-linear-blue"}>*/}
                {/*        View Report*/}
                {/*    </button>*/}
                {/*</a>*/}
            </div>
        </div>
    )
}

export default EmailComponent
