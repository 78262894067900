import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import projectService from "../../services/project.service";
import { useDispatch } from "react-redux";
import {
  clearErrorMessage,
  setErrorMessage,
  setSuccessMessage,
} from "../../redux/actions/message";

const UpdateProjectDetailLogic = () => {
  const { boardId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectDetail, setProjectDetail] = useState(null);
  const [bulkHour, setBulkHour] = useState(null);
  const [bulkMinute, setBulkMinute] = useState(null);
  const [emails, setEmails] = useState(null);
  const [teamEmails, setTeamEmails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reloadProject, setReloadProject] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("")

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleHideDeleteModal = () => setShowDeleteModal(false);

  const updateProject = () => {
    setLoading(true);
    const project = {
      bulkHour: parseInt(bulkHour),
      bulkMinute: parseInt(bulkMinute),
      email: emails.replace(/\s+/g, ""),
      emailTeam: teamEmails.replace(/\s+/g, ""),
    };

    projectService
      .updateProject(boardId, project)
      .then((res) => {
        if (res.data.success) {
          setReloadProject(true);
          setMessageSuccess(res.data.message)
          dispatch(clearErrorMessage());
        } else {
          dispatch(setErrorMessage(res.data.message));
        }
        setLoading(false);
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to update the project"));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (reloadProject) {
      setProjectDetail("");
      setBulkHour("");
      setBulkMinute("");
      setEmails("");
      setTeamEmails("");
      dispatch(clearErrorMessage());
      dispatch(setSuccessMessage(messageSuccess));
      
      projectService
      .projectDetail(boardId)
      .then((res) => {
        setProjectDetail(res.data.result.detail);
        setBulkHour(res.data.result.detail.bulkHour);
        setBulkMinute(res.data.result.detail.bulkMinute);
        res?.data?.result?.detail?.userEmail
          ? setEmails(res.data.result.detail.userEmail)
          : setEmails("");
        res?.data?.result?.detail?.teamEmail
          ? setTeamEmails(res.data.result.detail.teamEmail)
          : setTeamEmails("");
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to load the project detail"));
      });

      setTimeout(() => {
        setReloadProject(false);
        console.log(messageSuccess);
        dispatch(clearErrorMessage());
        dispatch(setSuccessMessage(""));
      }, 5000);
    }
  }, [reloadProject, dispatch, messageSuccess, boardId]);

  const deleteProject = () => {
    setDeleteLoading(true);
    projectService
      .deleteProject(boardId)
      .then((res) => {
        if (res.data.success) {
          dispatch(clearErrorMessage());
          dispatch(setSuccessMessage(res.data.message));
          navigate("/dashboard");
        } else {
          dispatch(setErrorMessage(res.data.message));
        }
        setDeleteLoading(false);
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to delete the project"));
        setDeleteLoading(false);
      });
  };

  const back = () => {
    navigate("/project-detail/" + boardId);
  };

  useEffect(() => {
    projectService
      .projectDetail(boardId)
      .then((res) => {
        setProjectDetail(res.data.result.detail);
        setBulkHour(res.data.result.detail.bulkHour);
        setBulkMinute(res.data.result.detail.bulkMinute);
        res?.data?.result?.detail?.userEmail
          ? setEmails(res.data.result.detail.userEmail)
          : setEmails("");
        res?.data?.result?.detail?.teamEmail
          ? setTeamEmails(res.data.result.detail.teamEmail)
          : setTeamEmails("");
        dispatch(clearErrorMessage());
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to load the project detail"));
      });
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [boardId, dispatch]);

  return {
    projectDetail,
    loading,
    showDeleteModal,
    deleteLoading,
    bulkHour,
    bulkMinute,
    emails,
    setBulkHour,
    setBulkMinute,
    setEmails,
    teamEmails,
    setTeamEmails,
    updateProject,
    handleShowDeleteModal,
    handleHideDeleteModal,
    deleteProject,
    back,
  };
};

export default UpdateProjectDetailLogic;
