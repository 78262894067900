import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import addDays from "date-fns/addDays";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import getMonth from "date-fns/getMonth";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import MONTHS from "../../../data/MONTHS.json";
import publicService from "../../../services/public.service";
import { useDispatch } from "react-redux";
import {
  clearErrorMessage,
  setErrorMessage,
} from "../../../redux/actions/message";

const PublicProjectDetailLogic = () => {
  const { boardId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const months = MONTHS;
  const [month, setMonth] = useState(getMonth(new Date()));
  const [projectDetail, setProjectDetail] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });

  const tableColumns = [
    {
      Header: "Task Name",
      accessor: "name",
    },
    {
      Header: "Task Duration",
      accessor: "bulkHour",
    },
  ];

  const filterAllSelected = () => {
    setSelectedFilter("ALL");
    setDate({
      startDate: null,
      endDate: null,
    });
  };
  const filterWeekSelected = () => {
    setSelectedFilter("WEEK");
    setDate({
      startDate: startOfWeek(new Date(), { weekStartsOn: 6 }),
      endDate: endOfWeek(new Date(), { weekStartsOn: 6 }),
    });
  };
  const filterMonthSelected = () => {
    setSelectedFilter("MONTH");
    setDate({
      startDate: startOfMonth(new Date(new Date().getFullYear(), month, 1)),
      endDate: endOfMonth(new Date(new Date().getFullYear(), month, 1)),
    });
  };
  const filterDateRangeSelected = () => setSelectedFilter("DATE_RANGE");

  const nextWeek = () => {
    setDate({
      startDate: addDays(new Date(date.startDate), 7),
      endDate: addDays(new Date(date.endDate), 7),
    });
  };
  const prevWeek = () => {
    setDate({
      startDate: subDays(new Date(date.startDate), 7),
      endDate: subDays(new Date(date.endDate), 7),
    });
  };
  const handleChangeMonth = (month) => {
    setMonth(month);
    setDate({
      startDate: startOfMonth(new Date(new Date().getFullYear(), month, 1)),
      endDate: endOfMonth(new Date(new Date().getFullYear(), month, 1)),
    });
  };
  const handleDateRangeSelect = (ranges) => {
    filterDateRangeSelected();
    setDate({
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate,
    });
  };

  const updateProjectDetail = () => {
    navigate("/update/" + boardId);
  };

  const loadProjectDetail = useCallback(() => {
    const start = date.startDate && format(date.startDate, "yyyy-MM-dd");
    const end = date.endDate && format(date.endDate, "yyyy-MM-dd");
    publicService
      .publicProjectDetail(boardId, start, end)
      .then((res) => {
        setProjectDetail(res.data.result.detail);
        setTasks(res.data.result.tasks);
        dispatch(clearErrorMessage());
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to load the project detail"));
      });
  }, [boardId, date, dispatch]);

  useEffect(() => {
    setTasks(null);
    loadProjectDetail();
  }, [loadProjectDetail]);

  return {
    projectDetail,
    tasks,
    selectedFilter,
    month,
    date,
    months,
    tableColumns,
    handleChangeMonth,
    filterAllSelected,
    filterWeekSelected,
    filterMonthSelected,
    nextWeek,
    prevWeek,
    handleDateRangeSelect,
    updateProjectDetail,
  };
};

export default PublicProjectDetailLogic;
