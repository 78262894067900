import { useDispatch, useSelector } from "react-redux";
import { clearErrorMessage } from "../../redux/actions/message";

const ErrorAlertLogic = () => {
  const dispatch = useDispatch();
  const { errorMessage } = useSelector((state) => state.message);

  const closeAlert = () => {
    dispatch(clearErrorMessage());
  };

  return {
    errorMessage,
    closeAlert,
  };
};

export default ErrorAlertLogic;
