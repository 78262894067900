import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Placeholder from "react-bootstrap/Placeholder";
import Form from "react-bootstrap/Form";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import PublicProjectDetailLogic from "./PublicProjectDetailLogic";
import DataTable from "../../../components/DataTable/DataTable";
import Header from "../../../components/Header/Header";

export default function PublicProjectDetail() {
  const logic = PublicProjectDetailLogic();
  return (
    <Container>
      <Header />
      <Row className="my-3">
        <Navbar variant="lightgrey">
          {logic.projectDetail ? (
            <Stack direction="horizontal" className="align-items-center">
              <div className="fw-bold me-1">
                {logic.projectDetail.boardName}:
              </div>
              <div>{logic.projectDetail.totalLogged} Logged</div>
            </Stack>
          ) : (
            <Placeholder xs={3} />
          )}

          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link
                className={logic.selectedFilter === "ALL" && "bg-lightblue"}
                disabled={logic.selectedFilter === "ALL"}
                onClick={logic.filterAllSelected}
              >
                All
              </Nav.Link>
              <Nav.Link
                className={logic.selectedFilter === "WEEK" && "bg-lightblue"}
                disabled={logic.selectedFilter === "WEEK"}
                onClick={logic.filterWeekSelected}
              >
                Week
              </Nav.Link>
              <Nav.Link
                className={logic.selectedFilter === "MONTH" && "bg-lightblue"}
                disabled={logic.selectedFilter === "MONTH"}
                onClick={logic.filterMonthSelected}
              >
                Month
              </Nav.Link>
              <NavDropdown
                id="dropdown-basic"
                title="Date Range"
                align="end"
                className={
                  logic.selectedFilter === "DATE_RANGE" && "bg-lightblue"
                }
                disabled={logic.selectedFilter === "DATE_RANGE"}
              >
                <DateRangePicker
                  ranges={[logic.date]}
                  onChange={logic.handleDateRangeSelect}
                  showMonthAndYearPickers={false}
                  staticRanges={[]}
                  inputRanges={[]}
                  rangeColors={["#003A96"]}
                />
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Row>
      <Row>
        <Col>
          {logic.projectDetail ? (
            <Stack direction="horizontal">
              <div className="fw-bold me-1">Status: </div>
              {logic.projectDetail.isUpToDate ? (
                <div>Up to date</div>
              ) : (
                <div>Not up to date</div>
              )}
            </Stack>
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={2} />
            </Placeholder>
          )}
        </Col>
      </Row>
      <Row className="my-3 d-flex flex-row align-items-center">
        <Col sm={3}>
          <Button variant="blue" onClick={logic.updateProjectDetail}>
            Update Project Detail
          </Button>
        </Col>
        <Col sm={6} className="d-flex flex-row justify-content-center">
          {logic.selectedFilter === "WEEK" ? (
            logic.date.startDate && (
              <Stack direction="horizontal" gap={2}>
                <Button
                  className="shadow-sm"
                  variant="light"
                  onClick={logic.prevWeek}
                >
                  &#60;
                </Button>
                {format(logic.date.startDate, "dd MMMM")} -{" "}
                {format(logic.date.endDate, "dd MMMM")}
                <Button
                  className="shadow-sm"
                  variant="light"
                  onClick={logic.nextWeek}
                >
                  &#62;
                </Button>
                <div className="fst-italic" style={{ fontSize: 8 }}>
                  the date is from Saturday - Friday
                </div>
              </Stack>
            )
          ) : logic.selectedFilter === "MONTH" ? (
            <Stack direction="horizontal" gap={2}>
              <div>Showing for: </div>
              <div>
                <Form.Select
                  className="shadow border-0"
                  value={logic.month}
                  onChange={(e) => logic.handleChangeMonth(e.target.value)}
                >
                  {logic.months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Stack>
          ) : logic.selectedFilter === "DATE_RANGE" ? (
            <Stack direction="horizontal" gap={2}>
              <div>Showing Task from</div>
              <div className="fw-bold">
                {format(logic.date.startDate, "dd MMMM")}
              </div>{" "}
              to
              <div className="fw-bold">
                {format(logic.date.endDate, "dd MMMM")}.
              </div>
              <NavDropdown id="dropdown-basic" title="Change Date" align="end">
                <DateRangePicker
                  ranges={[logic.date]}
                  onChange={logic.handleDateRangeSelect}
                  showMonthAndYearPickers={false}
                  staticRanges={[]}
                  inputRanges={[]}
                  rangeColors={["#003A96"]}
                />
              </NavDropdown>
            </Stack>
          ) : (
            <></>
          )}
        </Col>
        <Col sm={3}></Col>
      </Row>
      <DataTable columns={logic.tableColumns} data={logic.tasks} />
    </Container>
  );
}
