import {
  CLEAR_ERROR_MESSAGE,
  CLEAR_MESSAGE,
  CLEAR_SUCCESS_MESSAGE,
  SET_ERROR_MESSAGE,
  SET_SUCCESS_MESSAGE,
} from "../actions/types";
const initialState = {};
const messageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ERROR_MESSAGE:
      return { ...state, errorMessage: payload };
    case SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: payload };
    case CLEAR_MESSAGE:
      return { errorMessage: null, successMessage: null };
    case CLEAR_ERROR_MESSAGE:
      return { ...state, errorMessage: null };
    case CLEAR_SUCCESS_MESSAGE:
      return { ...state, successMessage: null };
    default:
      return state;
  }
};

export default messageReducer;
