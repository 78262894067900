import React from "react";
import Alert from "react-bootstrap/Alert";
import SuccessAlertLogic from "./SuccessAlertLogic";

export default function SuccessAlert() {
  const logic = SuccessAlertLogic();
  return logic.successMessage ? (
    <Alert variant="success" onClose={logic.closeAlert} dismissible>
      <p>{logic.successMessage}</p>
    </Alert>
  ) : (
    <></>
  );
}
