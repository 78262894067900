import React from "react";
import Container from "react-bootstrap/Container";
import Header from "../../../components/Header/Header";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Placeholder from "react-bootstrap/Placeholder";
import Spinner from "react-bootstrap/Spinner";
import PublicUpdateProjectLogic from "./PublicUpdateProjectLogic";
import Stack from "react-bootstrap/Stack";

export default function PublicUpdateProject() {
  const logic = PublicUpdateProjectLogic();
  return (
    <Container>
      <Header />
      <Row className="mt-5">
        <Col>
          {logic.projectDetail ? (
            <h4>{logic.projectDetail.boardName}</h4>
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={3} />
            </Placeholder>
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={5}>
          <h6>User Email</h6>
          <p>You can add up to 5 email(s), separated by coma (,)</p>
          {logic.emails !== null ? (
            <Form.Control
              value={logic.emails}
              as="textarea"
              type="text"
              placeholder="insert emails.."
              onChange={(e) => logic.setEmails(e.target.value)}
            />
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {logic.projectDetail ? (
            <Stack direction="horizontal" gap={2}>
              <Button
                variant="blue"
                className="mt-3"
                onClick={logic.updateProject}
              >
                {logic.loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Update Project"
                )}
              </Button>
              <Button variant="darkblue" className="mt-3" onClick={logic.back}>
                Back
              </Button>
            </Stack>
          ) : (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={2} />
            </Placeholder>
          )}
        </Col>
      </Row>
    </Container>
  );
}
