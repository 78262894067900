const getLocalAccessToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

const removeLocalAccessToken = () => {
  localStorage.removeItem("token");
};

const tokenService = {
  getLocalAccessToken,
  removeLocalAccessToken,
};

export default tokenService;
