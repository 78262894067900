import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";
import Logo from "../../assets/images/Logo.png";
import LoginLogic from "./LoginLogic";

export default function Login() {
  const logic = LoginLogic();
  return (
    !logic.token && (
      <Container>
        <Row className="justify-content-center align-items-center vh-100">
          <Col md={4} className="d-flex flex-column align-items-center">
            <Image src={Logo} fluid width="250px" className="m-3" />
            <Row className="my-3 align-items-center w-100">
              <Col sm={4}>
                <Form.Label>Username</Form.Label>
              </Col>
              <Col sm={8}>
                <Form.Control
                  type="email"
                  placeholder="user@example.com"
                  id="username"
                  onChange={logic.handleFieldChange}
                />
              </Col>
            </Row>
            <Row className="my-3 align-items-center w-100">
              <Col sm={4}>
                <Form.Label>Password</Form.Label>
              </Col>
              <Col sm={8}>
                <Form.Control
                  type="password"
                  placeholder="******"
                  id="password"
                  onChange={logic.handleFieldChange}
                />
              </Col>
            </Row>
            {logic.errorMessage && (
              <p className="text-danger">{logic.errorMessage}</p>
            )}
            <Button variant="blue" className="px-5 m-3" onClick={logic.handleLogin}>
              {logic.loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "LOGIN"
              )}
            </Button>
          </Col>
        </Row>
      </Container>
    )
  );
}
