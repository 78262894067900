import React from "react";
import Container from "react-bootstrap/Container";
import Header from "../../components/Header/Header";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";
import NewProjectLogic from "./NewProjectLogic";

export default function NewProject() {
  const logic = NewProjectLogic();
  return (
    <Container>
      <Header />
      <Row className="mt-5">
        <Col sm={4} lg={3}>
          <h6>Board Id</h6>
          <Form.Control
            type="number"
            placeholder="insert board id here.."
            onChange={(e) => logic.setBoardId(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={5} lg={3}>
          <h6>Bulk Hours</h6>
          <Stack direction="horizontal" gap={2}>
            <Form.Control
              type="number"
              className="me-auto"
              onChange={(e) => logic.setBulkHour(e.target.value)}
            />
            <div>Hours</div>
            <Form.Control
              type="number"
              onChange={(e) => logic.setBulkMinute(e.target.value)}
            />
            <div>Minutes</div>
          </Stack>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={5}>
          <h6>User Email</h6>
          <p>You can add up to 5 email(s), separated by coma (,)</p>
          <Form.Control
            as="textarea"
            type="text"
            placeholder="insert emails.."
            onChange={(e) => logic.setEmails(e.target.value)}
          />
        </Col>
      </Row>
      <Stack direction="horizontal" gap={2}>
        <Button variant="blue" className="mt-3" onClick={logic.addProject}>
          {logic.loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "+ Add New Project"
          )}
        </Button>
        <Button variant="darkblue" className="mt-3" onClick={logic.back}>
          Back
        </Button>
      </Stack>
    </Container>
  );
}
