import api from "./api";

const publicProjectDetail = (boardId, startDate, endDate) => {
  if (startDate && endDate) {
    return api.get(
      "public/" + boardId + "?startDate=" + startDate + "&endDate=" + endDate
    );
  } else {
    return api.get("public/" + boardId);
  }
};

const publicUpdateProject = (boardId, project) =>
  api.put("public/" + boardId, project);

const publicService = {
  publicProjectDetail,
  publicUpdateProject,
};

export default publicService;
