import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import NewProject from "./pages/NewProject/NewProject";
import ProjectDetail from "./pages/ProjectDetail/ProjectDetail";
import PublicProjectDetail from "./pages/Public/PublicProjectDetail/PublicProjectDetail";
import PublicUpdateProject from "./pages/Public/PublicUpdateProject/PublicUpdateProject";
import UpdateProjectDetail from "./pages/UpdateProjectDetail/UpdateProjectDetail";
import PrivateOutlet from "./PrivateOutlet";
import EmailComponent from "./pages/Email/email.component";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/project/:boardId" element={<PublicProjectDetail />} />
        <Route path="/update/:boardId" element={<PublicUpdateProject />} />
        <Route path="/" element={<PrivateOutlet />}>
          <Route path="dashboard" element={<Dashboard />}></Route>
          <Route path="new-project" element={<NewProject />} />
          <Route path="project-detail/:boardId" element={<ProjectDetail />} />
          <Route
            path="update-project-detail/:boardId"
            element={<UpdateProjectDetail />}
          />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="email" element={<EmailComponent />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
