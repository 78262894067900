import authService from "../../services/auth.service";
import { setErrorMessage } from "./message";
import {
  CLEAR_MESSAGE,
  GET_USER_DATA,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
} from "./types";

export const loginAction = (credentials, onSuccess, onError) => (dispatch) =>
  authService
    .login(credentials)
    .then((res) => {
      if (res.data.success) {
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: CLEAR_MESSAGE,
        });
        onSuccess();
      } else {
        dispatch({
          type: LOGIN_FAILED,
        });
        dispatch(setErrorMessage(res.data.message));
        onError();
      }
    })
    .catch(() => {
      dispatch({
        type: LOGIN_FAILED,
      });
      dispatch(setErrorMessage("Error occured"));
      onError();
    });

export const logoutAction = (onSuccess, onError) => (dispatch) =>
  authService
    .logout()
    .then((res) => {
      if (res.data.success) {
        localStorage.removeItem("token");
        dispatch({
          type: LOGOUT,
        });
        onSuccess();
      } else {
        dispatch(setErrorMessage("Logout failed"));
        onError();
      }
    })
    .catch(() => {
      dispatch(setErrorMessage("Logout failed"));
      onError();
    });

export const getUserData = (onError) => (dispatch) =>
  authService
    .getUserData()
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_USER_DATA,
          payload: res.data,
        });
      } else {
        onError();
      }
    })
    .catch((error) => {
      // console.log(error?.response?.status);
      if(error?.response?.status === 401){
        localStorage.removeItem('token');
        // console.log(localStorage.getItem("token"))
        window.location.reload();
        
      }
      onError();
    });
