import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginAction } from "../../redux/actions/auth";
import tokenService from "../../services/token.service";

const LoginLogic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errorMessage } = useSelector((state) => state.message);
  const token = tokenService.getLocalAccessToken();

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.id]: e.target.value,
    });
  };

  const handleLogin = () => {
    setLoading(true);
    dispatch(
      loginAction(
        credentials,
        () => {
          setLoading(false);
          navigate("/dashboard");
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    token && navigate("/dashboard");
    return () => {};
  }, [navigate, token]);

  return {
    errorMessage,
    loading,
    token,
    navigate,
    handleFieldChange,
    handleLogin,
  };
};

export default LoginLogic;
