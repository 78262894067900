import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserData, logoutAction } from "../../redux/actions/auth";
import { setErrorMessage } from "../../redux/actions/message";
import tokenService from "../../services/token.service";

const HeaderLogic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const { authData } = useSelector((state) => state.auth);
  const token = tokenService.getLocalAccessToken();

  const logout = () => {
    setLogoutLoading(true);
    dispatch(
      logoutAction(
        () => {
          setLogoutLoading(false);
          navigate("/");
        },
        () => {
          setLogoutLoading(false);
          dispatch(setErrorMessage("Failed to logout"));
        }
      )
    );
  };

  useEffect(() => {
    token && !authData && dispatch(getUserData(() => {}));
    return () => {};
  }, [dispatch, authData, token]);

  return {
    token,
    authData,
    logoutLoading,
    navigate,
    logout,
  };
};

export default HeaderLogic;
