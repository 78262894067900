import {
  GET_USER_DATA,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../actions/types";

const initialState = {};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        authData: payload,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        authData: null,
      };
    case LOGOUT:
      return {
        ...state,
        authData: null,
      };
    case GET_USER_DATA:
      return {
        ...state,
        authData: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
