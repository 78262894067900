import React from "react";
import DataTableLogic from "./DataTableLogic";
import GlobalFilter from "./GlobalFilter";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Placeholder from "react-bootstrap/Placeholder";

export default function DataTable({
  columns,
  data,
  actionButtons,
  hiddenColumns,
}) {
  const logic = DataTableLogic(columns, data, hiddenColumns);
  return (
    <>
      <Row className="my-4">
        <Col sm={4} lg={3}>
          <Stack direction="horizontal" gap={2}>
            <div>Show</div>
            <Form.Select
              className="shadow border-0"
              value={logic.pageSize}
              onChange={(e) => logic.setPageSize(Number(e.target.value))}
            >
              {logic.pageSizeList.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
            <div>entries</div>
          </Stack>
        </Col>
        <Col sm={3} lg={5} className="my-2" />
        <Col sm={5} lg={4}>
          <GlobalFilter
            filter={logic.globalFilter}
            setFilter={logic.setGlobalFilter}
          />
        </Col>
      </Row>
      {data ? (
        <>
          <Table
            responsive
            striped
            borderless
            size="md"
            {...logic.getTableProps()}
          >
            <tbody {...logic.getTableBodyProps()}>
              {logic.headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="table-header"
                >
                  {headerGroup.headers.map((column) => (
                    /*<th {...column.getHeaderProps()} className="py-3">
                      {column.render("Header")}
                    </th>
                    */
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? '▼ '
                            : '▲ '
                          : ''}
                      </span>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      
                    </th>
                  ))}
                  {actionButtons && <th className="p-3">Action</th>}
                </tr>
              ))}
              {logic.page.map((row) => {
                logic.prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="table-row">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className={
                            cell.column.id === "isUpToDate"
                              ? cell.value
                                ? "text-success"
                                : "text-danger"
                              : cell.column.id === "boardName"
                              ? "fw-bold"
                              : null
                          }
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                    {actionButtons && (
                      <td className="text-nowrap">
                        {actionButtons.map((button) => (
                          <Button
                            key={button.name}
                            variant={button.variant}
                            size="sm"
                            className="m-1"
                            onClick={() => button.action(row.values)}
                          >
                            {button.name}
                          </Button>
                        ))}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data.length > 0 ? (
            <div className="d-flex flex-row justify-content-between">
              <p>
                Showing {logic.startEntry} to{" "}
                {logic.startEntry + logic.page.length - 1} entries
              </p>
              <Pagination>
                <Pagination.Item
                  disabled={!logic.canPreviousPage}
                  onClick={() => logic.previousPage()}
                >
                  Previous
                </Pagination.Item>
                <Pagination.Item
                  disabled={!logic.canNextPage}
                  onClick={() => logic.nextPage()}
                >
                  Next
                </Pagination.Item>
              </Pagination>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-center">
              <p>No data</p>
            </div>
          )}
        </>
      ) : (
        [...Array(logic.pageSize)].map((x, i) => (
          <Placeholder key={i} as="p" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        ))
      )}
    </>
  );
}
