import React from "react";
import Alert from "react-bootstrap/Alert";
import ErrorAlertLogic from "./ErrorAlertLogic";

export default function ErrorAlert() {
  const logic = ErrorAlertLogic();
  return logic.errorMessage ? (
    <Alert variant="danger" onClose={logic.closeAlert} dismissible>
      <p>{logic.errorMessage}</p>
    </Alert>
  ) : (
    <></>
  );
}
