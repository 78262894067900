import React from "react";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";

export default function GlobalFilter({ filter, setFilter }) {
  return (
    <span>
      <Stack direction="horizontal" gap={2}>
        <div>Search:</div>
        <Form.Control
          className="shadow border-0"
          type="text"
          placeholder="search anything here.."
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
        />
      </Stack>
    </span>
  );
}
